<template>
  <v-row no-gutters class="fill-height align-center justify-center">
    General info
  </v-row>
</template>

<script>
export default {
  name: "SettingsGeneralQuote"
};
</script>
